export const getBaseUrl = () => {
  if (typeof window !== "undefined") {
    return `${window.location.origin}/`;
  }
  if (process.env.AWS_PULL_REQUEST_ID) {
    return `https://pr-${process.env.AWS_PULL_REQUEST_ID}.${process.env.AWS_APP_ID}.amplifyapp.com`;
  }
  if (process.env.NODE_ENV === "production") {
    return "https://staging.selfmade-energy.com/";
  }
  return "http://localhost:3000/";
};
